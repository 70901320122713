$(document).ready(function () {

    $('.team').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });
    $('.fun').slick({
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear'
    });

    // $('#default-demo').slickLightbox();

});

$(window).scroll(function () {
    if ($(this).scrollTop() > 250) {
        $('header').addClass("sticky");
    }
    else {
        $('header').removeClass("sticky");
    }
});